import * as React from "react"
import { HeartIcon } from "@heroicons/react/24/outline"
import { HeartIcon as HeartSolidIcon } from "@heroicons/react/24/solid"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"

const Header = ({ image, color, labels, slug, donatesTo }) => {
  return (
    <header
      style={{ backgroundColor: color }}
      className={`rounded-b-3xl min-h-[50vh] pb-28`}
    >
      <img
        src={image} className="object-cover h-[300px] sticky top-0 z-10"
      />
      <div className="flex flex-row justify-center pt-2 pb-6 gap-x-4">
        {labels.map((label) => (
          <img key={label} src={label} className="h-12" />
        ))}
      </div>
      <div className="flex flex-row justify-center items-center relative z-0">
        <a href={`/charities/${donatesTo.replaceAll('_', '-')}/?candle=${slug}`} className="flex gap-x-2 flex-row justify-center items-center rounded-full text-white text-sm pl-3 pr-4 py-2 bg-[#00000033]">
          <div className="flex h-4 w-4 relative">
            <HeartSolidIcon className="h-4 w-4 opacity-50 animate-ping absolute inline-flex h-full w-full" />
            <HeartIcon className="h-4 w-4 relative inline-flex h-full w-full" />
          </div>
          <div className="opacity-75">This candle helped a charity</div>
          <div className="opacity-75"><ArrowLongRightIcon className="h-3 w-3" /></div>
        </a>
      </div>
    </header>
  )
}

export default Header
