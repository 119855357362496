import * as React from "react"
import Header from "./header"
import { HeartIcon } from "@heroicons/react/24/solid"
import { FireIcon, ScissorsIcon, RocketLaunchIcon } from "@heroicons/react/24/outline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Candle = ({ color, textColor, backgroundColor, fragrance, image, labels, buyMeACoffeeColor, coffeeColor, coffeeOutlineColor, spotifyPlaylist, slug, donatesTo, topUpURL }) => {
  return (
    <div className="container mx-auto max-w-xl static">
      <Header
        image={image}
        color={color}
        labels={labels}
        donatesTo={donatesTo}
        slug={slug}
      />
      <section className="sm:px-0 drop-shadow-xl bg-white relative -mt-20 left-0 right-0 mx-2 sm:mx-4 rounded-3xl">
        <div
          className="sticky top-0 p-0 sm:px-0"
        >
          <iframe
            className="rounded-t-3xl"
            src={`https://open.spotify.com/embed/playlist/${spotifyPlaylist}?utm_source=generator&theme=0`}
            width="100%"
            height="80"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy">
          </iframe>
        </div>
        <div className="p-4">
          <div>
            <p className="text-slate-600 px-4 py-8">{fragrance}</p>
          </div>
        </div>
        <div className="p-4 pt-0">
          <div className="bg-slate-50 rounded-lg p-4">
            <div className="pt-4">
              <p className="text-center font-light text-slate-500 uppercase text-s mb-1">Make the most out of your candle</p>
              <p className="text-center font-light text-slate-400 text-sm pb-8">Follow these tips to enjoy your candle for longer</p>
              <ul className="flex flex-col gap-4 sm:flex-row">
                <li className="w-full flex flex-col gap-2 items-center justify-start">
                  <strong className="text-sm text-slate-500">First burn</strong>
                  <FireIcon strokeWidth={1} className="w-12 h-12 text-slate-500" />
                  <p className="p-4 text-slate-600">
                    When burning for the first time, allow the candle to burn for at least <strong>two hours</strong> to avoid tunneling
                  </p>
                </li>
                <li className="w-full flex flex-col gap-2 items-center justify-start">
                  <strong className="text-sm text-slate-500">Wick trim</strong>
                  <ScissorsIcon strokeWidth={1} className="w-12 h-12 text-slate-500" />
                  <p className="p-4 text-slate-600">
                    Trim the wick to <strong>6mm (1/4") length</strong> before every burn
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-4">
          <p className="text-center font-light text-slate-500 uppercase text-s mb-1">Fully melted?</p>
          <p className="text-center font-light text-slate-400 text-sm pb-4">You can extend your candle lifespan!</p>
          <div className="py-4">
            <a
              href={topUpURL}
              onClick={() => window.fathom?.trackGoal("WGHFJKRG", 0)}
              style={{ backgroundColor: color, color: backgroundColor }}
              className="drop-shadow-md rounded-xl flex flex-row p-4 text-center uppercase font-medium text-md justify-center items-center">
              <div><RocketLaunchIcon className="h-4 w-4" /></div>
              <div className="mx-auto">Top-up your candle</div>
            </a>
          </div>
          <p className="text-slate-400 text-sm font-light">In order to reduce waste, NoBees candles can be topped-up. Naked scented candles that fit perfectly in your candle jar are available for purchase</p>
        </div>
      </section>
      <section className="text-center sm:text-right py-12 flex flex-col sm:flex-row gap-8 sm:gap-4 justify-center items-center">
        <p className="text-sm" style={{ color: textColor }}>
          <span className="opacity-40">This candle was hand poured</span>
          <br/>
          <span><span className="opacity-40">with</span> <HeartIcon className="h-4 w-4 inline text-red-600" /></span>
          <span className="opacity-40"> by <strong>Ashleigh</strong></span>
        </p>
        <a
          href="https://www.buymeacoffee.com/ashleighnobees"
          className="drop-shadow-md w-[200px]"
          onClick={() => window.fathom?.trackGoal("K1FWBW4D", 0)}
        >
          <img
            src={`https://img.buymeacoffee.com/button-api/?text=Buy her a coffee&slug=ashleighnobees&button_colour=${buyMeACoffeeColor.slice(1)}&font_colour=ffffff&font_family=Cookie&outline_colour=${coffeeOutlineColor.slice(1)}&coffee_colour=${coffeeColor.slice(1)}`}
          />
        </a>
      </section>
    </div>
  )
}

export default Candle
