import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Candle from "../components/candle"
import type { HeadFC } from "gatsby"

const CandlePage = ({ data }) => {
  const {slug, fragrance, spotifyPlaylist, theme, image, buyMeACoffee, labels, donatesTo, topUpURL} = data.candlesYaml

  return (
    <Layout style={{ backgroundColor: theme.backgroundColor }} theme={theme}>
      <Candle
        slug={slug}
        topUpURL={topUpURL}
        donatesTo={donatesTo}
        color={theme.color}
        backgroundColor={theme.backgroundColor}
        coffeeColor={buyMeACoffee.coffeeColor}
        coffeeOutlineColor={buyMeACoffee.outlineColor}
        buyMeACoffeeColor={buyMeACoffee.color}
        textColor={theme.textColor}
        spotifyPlaylist={spotifyPlaylist}
        image={image.publicURL}
        fragrance={fragrance}
        labels={labels.map(({ publicURL }) => publicURL)}
      />
    </Layout>
  )
}

export default CandlePage

export const Head: HeadFC = ({ data }) => {
  const { name, theme } = data.candlesYaml

  return (
    <>
      <title>{name} - NoBees Candles</title>
      <meta name="theme-color" content={theme.color} />
    </>
  )
}

export const query = graphql`
  query($id: String) {
    candlesYaml(id: { eq: $id }) {
      name
      slug
      fragrance
      labels {
        publicURL
      }
      image {
        publicURL
      }
      theme {
        color
        textColor
        backgroundColor
      }
      buyMeACoffee {
        coffeeColor
        outlineColor
        color
      }
      spotifyPlaylist
      donatesTo
      topUpURL
    }
  }
`
